<template>
  <div class="billing-period col-12 pa-0 mb-2">
    <div class="d-flex justify-space-between mb-1">
      {{ period.Description }}
    </div>
    <div class="d-flex justify-space-between mb-1">
      <div>
        {{ formatPrice(period.TotalNet / getPayPeriod(period.PeriodMultiplier), currency, 2) }} x {{ getPayPeriod(period.PeriodMultiplier) }}
        <span v-if="getPayPeriod(period.PeriodMultiplier) === 1">
          {{ $t('billwerk.customer-form.preview-order.month') }}
        </span>
        <span v-else>{{ $t('billwerk.customer-form.preview-order.months') }}</span>
      </div>
      <div class="billing-amount">
        {{ formatPrice(period.TotalNet, currency, 2) }}
      </div>
    </div>
    <div class="d-flex justify-space-between mb-1">
      <div>
        + {{ $t('billwerk.customer-form.preview-order.tax-label') }}
      </div>
      <div class="billing-vat">
        {{ formatPrice(period.TotalVat, currency, 2) }}
      </div>
    </div>
  </div>
</template>

<script>
import currency from '@/mixins/currency'

export default {
  mixins: [currency],
  props: {
    period: {
      type: Object,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    payPeriod: {
      type: Number,
      required: true
    },
    showDivider: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getPayPeriod (periodMultiplier) {
      return this.payPeriod * periodMultiplier
    }
  }
}
</script>

<style scoped>
.billing-period {
  font-size: 16px;
}
.billing-amount, .billing-vat {
  font-weight: bold;
}
</style>
