<template>
  <div class="d-flex flex-column">
    <VerifyTextField
      v-model="code"
      :label="$t('labels.coupon-code')"
      outlined
      :validate-button-text="$t('labels.apply-coupon')"
      :errors="code.length > 0 ? errors: []"
      :is-valid="coupon.CouponCode && isValid"
      :is-invalid="!!coupon?.ErrorMessage"
      :is-loading="isLoading"
      @validate="applyCoupon"
      @clear="onClear"
    />
  </div>
</template>

<script>
import featureMixin from '@/mixins/feature'
import VerifyTextField from '@/components/forms/VerifyTextField.vue'

export default {
  components: {
    VerifyTextField
  },
  mixins: [featureMixin],
  props: {
    coupon: {
      type: Object,
      default: null
    },
    couponCode: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      code: this.couponCode,
      isLoading: false,
      isValid: false,
      errors: []
    }
  },

  watch: {
    coupon: {
      handler (coupon) {
        this.isValid = coupon.isCouponValid
        this.isLoading = Boolean(this.code && coupon.CouponCode !== this.code.toUpperCase().replace(/[^A-Z0-9]/g, ''))
        if (this.code.length > 0 && coupon.ErrorCode) {
          this.errors = [{
            InvalidCouponCode: this.$t('error-messages.invalid-coupon-code'),
            CouponAlreadyUsed: this.$t('error-messages.used-coupon-code'),
            InactiveCouponCode: this.$t('error-messages.inactive-coupon-code')
          }[coupon.ErrorCode] || this.$t('error-messages.invalid-coupon-code')]
        } else {
          this.errors = []
        }
      },
      deep: true
    }
  },

  methods: {
    applyCoupon () {
      this.isLoading = true
      this.$emit('apply-coupon', this.code)
    },
    onClear () {
      this.code = ''
      this.errors = []
    }
  }
}

</script>
