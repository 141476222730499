<template>
  <div class="preview-order-container">
    <v-fade-transition>
      <div
        v-if="isPreviewOrderLoading"
        class="overlay"
      >
        <ProgressCircular />
      </div>
    </v-fade-transition>
    <div>
      <div class="billing">
        <div class="billing-label col-12 pa-0 mb-2">
          <span v-if="previewOrder.payPeriod === 12">
            {{ $t('billwerk.customer-form.preview-order.annual-billing') }}
          </span>
          <span v-else>{{ $t('billwerk.customer-form.preview-order.monthly-billing') }}</span>
        </div>
        <BillingPeriod
          v-for="(period, index) of previewOrder.billingPeriods"
          :key="period.Id"
          :period="period"
          :currency="previewOrder.currency"
          :pay-period="previewOrder.payPeriod"
          :show-divider="index !== previewOrder.billingPeriods.length - 1"
        />
      </div>
      <v-divider
        :style="vatBoxDivider"
        class="col-12 mb-2 pa-0 vat-box-divider"
      />
      <div
        v-if="hasValidCoupon"
        class="coupons"
      >
        <div class="col-12 coupon-label pa-0">
          {{ $t('billwerk.customer-form.preview-order.coupon-label') }}
        </div>
        <div
          class="d-flex justify-space-between mb-1"
        >
          <div>
            "{{ previewOrder.coupon.CouponCode }}"
          </div>
          <div class="billing-vat">
            {{ previewOrder.coupon.Description }}
          </div>
        </div>
      </div>
      <v-divider
        v-if="hasValidCoupon"
        :style="vatBoxDivider"
        class="col-12 mb-2 pa-0 vat-box-divider"
      />
      <div class="total d-flex justify-space-between">
        <div class="total-label">
          {{ $t('billwerk.customer-form.preview-order.total-price-label') }}
        </div>
        <div class="total-amount">
          {{ formatPrice(previewOrder.totalAfterVat, previewOrder.currency, 2) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProductPackageQuery } from '@/modules/productPackages/lib/productQuery'
import currencyMixin from '@/mixins/currency'
import ProgressCircular from '@/components/ProgressCircular.vue'
import BillingPeriod from './BillingPeriod.vue'

export default {
  components: {
    ProgressCircular,
    BillingPeriod
  },
  mixins: [currencyMixin],
  props: {
    previewOrder: {
      type: Object,
      required: true
    },
    isPreviewOrderLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      vatBoxDivider: {
        color: this.$vuetify.theme.themes.light.primary
      }
    }
  },
  computed: {
    productPackage () {
      return getProductPackageQuery()
    },
    isYearlyPaying () {
      return this.productPackage.isYearly
    },
    hasValidCoupon () {
      return this.previewOrder.coupon && this.previewOrder.coupon.isCouponValid
    }
  }
}
</script>

<style scoped>
.vat-box {
  font-size: 16px;
}

.total, .billing-label {
  font-size: 18px;
}

.billing-label, .coupon-label, .billing-amount, .billing-vat, .total-amount, .total-label {
  font-weight: bold;
}

.preview-order-container {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
}
</style>
