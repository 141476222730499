<template>
  <v-card
    class="radio-button"
    :class="{'primary active': isActive()}"
  >
    <v-radio
      ref="radio"
      :value="value"
      class="py-4 pl-4 pr-2"
      :color="isActive() ? 'white' : 'primary'"
    >
      <template v-slot:label>
        <div
          class="d-flex flex-column"
          :class="{'white--text': isActive()}"
        >
          <slot />
        </div>
      </template>
    </v-radio>
  </v-card>
</template>
<script>

export default {
  props: {
    value: {
      type: null,
      default: null
    }
  },
  methods: {
    isActive () {
      return this.$refs.radio?.isActive
    }
  }
}
</script>

<style scoped>
.radio-button {
  cursor: pointer;
  transition: filter 0.3s;
}

.radio-button:hover {
  filter: brightness(90%);
}

.active:hover {
  filter: brightness(120%);
}
</style>
