<template>
  <div class="d-flex align-center flex-column">
    <div>
      <h1 class="mb-4 text-center display-1 black--text font-weight-black">
        <span>{{ $t('payment.title') }}</span>
      </h1>
    </div>
    <v-row class="text-center">
      <v-col>
        <div class="flex-row flex-wrap mt-2 benefits d-flex text-caption mb-md-6 justify-md-center">
          <p class="px-0 mb-2 mx-md-8 nowrap">
            <v-icon
              class="px-0 ml-0 mr-2 check-icon"
              :style="{'color': $vuetify.theme.themes.light.markero.blue}"
            >
              mdi-check-circle
            </v-icon>
            {{ $t('payment.benefits.option-one') }}
          </p>
          <p class="px-0 mb-2 mr-md-8 mx-md-8 nowrap">
            <v-icon
              class="px-0 ml-0 mr-2 check-icon"
              :style="{'color': $vuetify.theme.themes.light.markero.blue}"
            >
              mdi-check-circle
            </v-icon>
            <span v-if="isYearly"> {{ $t('payment.benefits.option-two') }}</span>
            <span v-else> {{ $t('payment.benefits.option-three') }}</span>
          </p>
          <p class="px-0 mb-2 mx-md-8 nowrap">
            <v-icon
              class="px-0 ml-0 mr-2 check-icon"
              :style="{'color': $vuetify.theme.themes.light.markero.blue}"
            >
              mdi-check-circle
            </v-icon>
            {{ $t('payment.benefits.option-four') }}
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    isYearly: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>
@media (max-width: 600px) {
  .benefits {
    justify-content: space-evenly;
  }
}

.nowrap {
  white-space: nowrap;
}
</style>
